import { Injectable } from '@angular/core';
import { filter, map, Observable, repeat, take, tap } from 'rxjs';
import {
  MaterialsListRequestParams,
  MaterialsService as ApiMaterialsService,
  MaterialRequest,
  MaterialsChangelogListRequestParams,
  UploadFileRequest,
  MaterialsDownloadRetrieve200Response,
  FileParsingStatusResponse,
} from '@cat-ai-us-fe/api';

@Injectable({
  providedIn: 'root',
})
export class MaterialsService {
  constructor(private readonly service: ApiMaterialsService) {}

  materialsList(requestParameters: MaterialsListRequestParams = {}) {
    return this.service
      .materialsList(requestParameters)
      .pipe(map((material) => material.results));
  }

  getMaterial(id: number) {
    return this.service.materialsRetrieve({
      id,
    });
  }

  getMaterialLogs(params: MaterialsChangelogListRequestParams) {
    return this.service.materialsChangelogList(params);
  }

  createFormMaterial(formId: number, title: string) {
    return this.service.materialsCreate({
      materialRequest: {
        object_id: formId,
        type: MaterialRequest.TypeEnum.Form,
        title,
      },
    });
  }

  downloadFile(
    materialId: number,
  ): Observable<MaterialsDownloadRetrieve200Response> {
    return this.service.materialsDownloadRetrieve({
      id: materialId,
    });
  }

  uploadMaterial(url: string, kind: UploadFileRequest.KindEnum) {
    return this.service.materialsUploadFileCreate({
      uploadFileRequest: {
        url,
        kind,
      },
    });
  }

  checkDocumentParsingStatus(
    documentIds: number,
  ): Observable<FileParsingStatusResponse[]> {
    const fileParsingStatusRequest = {
      file_ids: [documentIds],
    };
    return this.service
      .materialsFileParsingStatusCreate({ fileParsingStatusRequest })
      .pipe(
        repeat({ delay: 1000 }),
        filter((response) => {
          return !!response.results.find(
            (result) => result.status === 'parsed_success',
          );
        }),
        take(1),
        map((response) => <FileParsingStatusResponse[]>response.results),
      );
  }
}
